<template>
    <div>
        <div
          class="d-flex justify-content-center align-items-center"
          style="height: 100vh"
        >
          <div class="mr-3">
            <h1>
              Logging into 
              <div class="text-capitalize" v-if="window.location.host != 'klizer.zircly.com'">
                DCKAP IT Support 
              </div>
              <div class="text-capitalize" v-else>
                Klizer IT Support 
              </div>

               from Zircly. 
              Please Wait!
            </h1>
          </div>
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              class="mr-1"
              variant="primary"
            />
          </div>
        </div>
      </div>
  </template>
  
  <script>
import { BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
  
  
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardBody,
    },
    created(){
      localStorage.setItem("pathToLoadAfterLogin", window.location.pathname);
      if(this.$cookies.get("userData")){
        if(window.location.host == 'klizer.zircly.com' || window.location.host == 'dckap.zircly.com' || window.location.host == 'dckap.dckap.co' || window.location.host == 'localhost:8080' ){
          var token = Vue.$cookies.get('accessToken');
          if(window.location.host != 'klizer.zircly.com'){
            var IT_LINK = 'https://tickets.dckap.co/login/checkzircly?ac_token='+token;
          }else{
            var IT_LINK = 'https://tickets.klizer.com/login/checkzircly?ac_token='+token;
          }
          window.location.replace(IT_LINK);
        }
      }
      
    }
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  